import React from 'react';
import {ErrorMessage} from "formik";

type Props = {
    name: string
}

const FieldError = ({name}: Props) => {
    return (
        <div className="text-danger font-size-xs">
            <ErrorMessage name={name} />
        </div>
    );
};

export default FieldError;