export const formatCurrency = (value: number, currency: string) => {
    if (value === undefined || !currency) return ""
    const noFractionCurrencies = ["MXN", "PEN"]
    let minFractionDigits = 2
    if (noFractionCurrencies.includes(currency.toUpperCase())) minFractionDigits = 0
    const userLang = navigator.language || navigator.languages[0];
    return new Intl.NumberFormat(
        userLang,
        {
            style: "currency",
            currency: currency.toUpperCase(),
            minimumFractionDigits: minFractionDigits,
            maximumFractionDigits: 2,
        },
    ).format(value)
}