import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {AuthState, checkLocalStorageTokens} from "../../../actions/auth";
import {StudioState} from "../../../actions/studio";
import CheckoutBricksMercadoPago from "./index";
import {useLocation} from "react-router-dom";

const CheckoutBricksStudioLoadScreen = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const authState = useSelector<StoreState, AuthState>(state => state.auth)
    const studioState = useSelector<StoreState, StudioState>(state => state.studio)
    const [preferenceId, setPreferenceId] = useState("");

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const access = params.get("access") || undefined
        setPreferenceId(params.get("preferenceId") || "")
        dispatch(checkLocalStorageTokens(access))
        // setPreferenceId(params.get("preferenceId") || undefined)

    }, []);


    if (!authState.isLoggedIn || !studioState.studio?.preferences?.public)
        return (
            <div />
        );
    return <CheckoutBricksMercadoPago public_key={studioState.studio.preferences.public} preferenceId={preferenceId}/>
};

export default CheckoutBricksStudioLoadScreen;
