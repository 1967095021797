import React, {useEffect, useRef, useState} from 'react';
import { useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import baseApi from "../../../apis/baseApi";
import {AuthState} from "../../../actions/auth";
import {useNavigate} from "react-router-dom";
import useMercadopago from "../../../hooks/mercadoPago";
import {Data, MercadoPagoPayment, Payer} from "./interfaces/MercadoPagoPayment";
import {MercadoPagoPreference} from "./interfaces/MercadoPagoPreference";
import {Button, Image} from "react-bootstrap";
import mpLogo from "../../../assets/images/Mercado-Pago-Logo.png"


interface Props {
    public_key: string
    preferenceId: string
}

const CheckoutBricksMercadoPago = ({public_key, preferenceId}: Props) => {
        const navigate = useNavigate()
        const authState = useSelector<StoreState, AuthState>(state => state.auth)

        const [loading, setLoading] = useState(true);
        const [errorMsg, setErrorMsg] = useState<string>();
        const [preference, setPreference] = useState<MercadoPagoPreference>();
        const mp = useMercadopago(public_key)
        const paymentBrickController = useRef<any>();



        useEffect(() => {
            if (preferenceId && authState.isLoggedIn && !preference) {
                baseApi.get<MercadoPagoPreference>(`/payments-mp/preference/${preferenceId}/`).then(resp => {
                    setPreference(resp.data)
                })
            }
        }, [preferenceId, authState]);


        useEffect(() => {
            if (mp && preference) {
                const bricksBuilder = mp.bricks();
                // console.log(bricksBuilder)
                console.log(bricksBuilder)
                // setBricksBuilderItem(bricksBuilder)
                const proKeys :string[] = [
                    "TEST-f3bb3471-ec47-4a37-b160-4c4c0e0b3a4d",
                    "APP_USR-7b31c115-a4fe-41a7-ba89-58e132ddb862",
                ]
                if (proKeys.includes(public_key)) {
                    renderCheckoutProBrick(bricksBuilder)
                } else {
                    renderPaymentBrick(bricksBuilder)
                }
            }
        }, [mp, preference]);

        const renderStatusBrick = async (bricksBuilder: any, paymentId: number) => {
            const statusSettings = {
                initialization: {
                    paymentId: paymentId, // Payment identifier, from which the status will be checked
                },
                customization: {
                    visual: {
                        hideStatusDetails: false,
                        hideTransactionDate: true,
                        style: {
                            theme: 'default', // 'default' | 'dark' | 'bootstrap' | 'flat'
                        }
                    },
                    backUrls: {
                        success: 'http://192.168.1.143:3001',
                        error: 'http://192.168.1.143:3001',
                        'return': 'http://192.168.1.143:3001',
                    }
                },
                callbacks: {
                    onReady: () => {
                        paymentBrickController.current.unmount()
                    },
                    onError: (err: any) => {
                        // Callback called for all Brick error cases
                    },
                }
            }
            bricksBuilder.create('statusScreen', 'statusScreenBrick_container', statusSettings);
        }

        const renderCheckoutProBrick = async (bricksBuilder: any) => {
            // @ts-ignore
            mp!.bricks().create("wallet", "wallet_container", {
                initialization: {
                    preferenceId: preference?.id,
                },
                customization: {
                    texts: {
                        valueProp: 'smart_option',
                    },
                },
            });
        }

        const renderPaymentBrick = async (bricksBuilder: any) => {
            const total_amount = preference?.items.reduce((currentValue, item) => currentValue + (item.unit_price * item.quantity), 0)
            const settings = {
                initialization: {
                    amount: total_amount,
                    preferenceId: preference?.id,
                    items: {
                        totalItemsAmount: total_amount,
                        itemsList: preference?.items.map(item => ({
                            units: item.quantity,
                            value: item.unit_price,
                            name: item.title,
                        })),
                    },
                    payer: {
                        firstName: "",
                        lastName: "",
                        email: "",
                    },
                },
                customization: {
                    enableReviewStep: true,
                    visual: {
                        style: {
                            theme: "default",
                        },
                    },
                    paymentMethods: {
                        creditCard: "all",
                        debitCard: "all",
                        wallet_purchase: "all",
                        // mercadoPago: "all",
                        maxInstallments: 1
                    },
                },
                callbacks: {
                    onReady: () => {
                        setLoading(false)
                    },
                    onSubmit: ({formData}: PaymentData) => {
                        console.log("DATA")
                        console.log(formData)
                        baseApi.post<MercadoPagoPayment>("/payments-mp/bricks/process-payment/", {
                            ...formData,
                            metadata: preference?.metadata,
                            preference_id: preference?.id,
                        })
                            .then(resp => {
                                console.log(resp)
                                renderStatusBrick(bricksBuilder, resp.data.id)
                            }).catch(err => {
                        })
                    },
                    onError: (error: any) => {
                        // callback llamado para todos los casos de error de Brick
                        console.error(error);
                    },
                }
            }
            // @ts-ignore
            paymentBrickController.current = await bricksBuilder.create(
                "payment",
                "paymentBrick_container",
                settings,
            )
            // console.log(controller)
            // console.log(typeof controller)
        }
        if (!preferenceId) {
            return <div>Cargando</div>
        }
        return (
            <div>
                {/*<h1>BRICKS BUILDER</h1>*/}
                {/*<div>AUTH: {authState.isLoggedIn ? "LOGGED IN" : "NO"}</div>*/}
                {/*<div>AUTH LOADING: {authState.loading ? "LOADING" : ""}</div>*/}
                {/*<div>AUTH ERROR: {authState.error ? authState.error.toString() : ""}</div>*/}
                {/*<div>TOKEN: {token}</div>*/}
                <div className="text-center bg-white">
                    <img src={mpLogo} alt="logo mercadopago" className="img-thumbnail border-0 shadow-none" style={{maxWidth: "150px"}}/>
                </div>
                <div id="statusScreenBrick_container"/>
                <div>
                    <div id="paymentBrick_container"/>
                </div>
                <div id="wallet_container"></div>
                <div className="text-center">
                    <Button onClick={() => {
                        navigate("/", {replace: true})
                    }} variant="link">Regresar a la app</Button>
                </div>
            </div>
        );
    }
;

export default CheckoutBricksMercadoPago;


interface PaymentData {
    paymentType: string
    selectedPaymentMethod: string
    formData: FormData
}

interface FormData {
    token: string
    issuer_id: string
    payment_method_id: string
    transaction_amount: number
    installments: number
    payer: Payer
}


export interface PaymentMethod {
    id: string
    type: string
    issuer_id: string
    data: Data
}

