
import jwt_decode from "jwt-decode";
import {isAfter} from "date-fns";
import {store} from "../index";
import noAuthApi from "../apis/noAuthApi";
import {logout} from "../actions/auth";

interface TokenPayload {
    user_id: string
    exp: number
    jti: string
}

export const isTokenExpired = (token: string) => {
    const tokenPayload = jwt_decode(token) as TokenPayload
    return isAfter(new Date(), new Date(tokenPayload.exp * 1000))
}

export const refreshAccess = async () => {
    const refresh = localStorage.getItem("refresh")
    if (isTokenExpired(refresh ?? "")) {
        localStorage.removeItem("access")
        localStorage.removeItem("refresh")
        // @ts-ignore
        store.dispatch(logout())
    }
    const resp = await noAuthApi.post("/auth/jwt/refresh/", {refresh})
    localStorage.setItem("access", resp.data.access)
}