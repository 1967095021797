import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {AuthState, checkLocalStorageTokens, logout} from "../../../actions/auth";
import Plans from "../Plans";
import {StudioState} from "../../../actions/studio";
import {useNavigate, useLocation} from "react-router-dom";
import {ColorsState, updateColors} from "../../../actions/colors";

const Home = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = new URLSearchParams(location.search)

    const authState = useSelector<StoreState, AuthState>(state => state.auth)
    const studioState = useSelector<StoreState, StudioState>(state => state.studio)
    const colorsState = useSelector<StoreState, ColorsState>(state => state.colors)


    useEffect(() => {
        const access = params.get("access") || undefined
        console.log(access)
        dispatch(checkLocalStorageTokens(access))
        if (!colorsState.updated) {
            const fg = params.get('fg') || '#2295F3'
            const bg = params.get('bg') || 'white'
            const text = params.get('text') || 'black'
            dispatch(updateColors({fg: fg, bg: bg, text: text, updated: true}))
        }
    }, [location, dispatch, colorsState]);

    useEffect(() => {
        if (authState.loading || authState.submitting) return
        if (!authState.isLoggedIn) {
            const params = new URLSearchParams(location.search)
            const branch = params.get('branch') || undefined
            const studio = params.get('studio') || undefined
            if (!branch || !studio) {
                navigate('/login', {replace: true})
            }
        }
    }, [dispatch, authState, studioState, location]);


    if (authState.loading) {
        return <div className='font-size-h6'>Cargando...</div>
    }

    return (
        <div>
            <Plans/>

            {/*<div className="text- center">*/}
            {/*    <button hidden={!authState.isLoggedIn || params.get("access") !== undefined} onClick={() => {*/}
            {/*        dispatch(logout())*/}
            {/*    }} className="btn btn-outline-secondary border-0 btn-sm mt-4 d-none d-sm-block text-center justify-content-center"><span*/}
            {/*        className="text-muted text-center float-center"> Cerrar Sesión </span></button>*/}
            {/*</div>*/}
        </div>
    );
};

export default Home;
