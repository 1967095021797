import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import baseApi from "../../../apis/baseApi";

const SubscriptionSuccess = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [session, setSession] = useState<StripeSession>();

    useEffect(() => {
        // const params = new URLSearchParams(location.search)
        // const sessionId = params.get("session_id")
        // baseApi.get(`/stripe/session/?id=${sessionId}`).then(resp=> {
        //     setSession(resp.data)
        //     setLoading(false)
        // })
    }, []);


    return (
        <div>
            <div className="alert alert-success mt-4">¡Suscripción Iniciada!</div>
            <div className="font-size-h6">Tu membresía se renovara automáticamente al final del plazo.</div>
            <div className="text-muted font-weight-bolder">Es posible que sea necesario reiniciar tu app para ver la nueva membresía acreditada.</div>
            <button onClick={() => {
                window.close()
            }} className="btn btn-primary mt-4">Cerrar ventana
            </button>
            {/*<div hidden={!session}>*/}
            {/*    <h4>Detalles</h4>*/}
            {/*    */}
            {/*</div>*/}

        </div>
    );
};

export default SubscriptionSuccess;

interface AutomaticTax {
    enabled: boolean;
    status?: any;
}

interface Address {
    city?: any;
    country: string;
    line1?: any;
    line2?: any;
    postal_code?: any;
    state?: any;
}

interface CustomerDetails {
    address: Address;
    email: string;
    name: string;
    phone?: any;
    tax_exempt: string;
    tax_ids: any[];
}

interface Metadata {
}

interface PhoneNumberCollection {
    enabled: boolean;
}

interface TotalDetails {
    amount_discount: number;
    amount_shipping: number;
    amount_tax: number;
}

interface StripeSessionObject {
    id: string;
    object: string;
    after_expiration?: any;
    allow_promotion_codes?: any;
    amount_subtotal: number;
    amount_total: number;
    automatic_tax: AutomaticTax;
    billing_address_collection?: any;
    cancel_url: string;
    client_reference_id: string;
    consent?: any;
    consent_collection?: any;
    currency: string;
    customer: string;
    customer_creation?: any;
    customer_details: CustomerDetails;
    customer_email?: any;
    expires_at: number;
    livemode: boolean;
    locale?: any;
    metadata: Metadata;
    mode: string;
    payment_intent?: any;
    payment_link?: any;
    payment_method_collection: string;
    payment_method_options?: any;
    payment_method_types: string[];
    payment_status: string;
    phone_number_collection: PhoneNumberCollection;
    recovered_from?: any;
    setup_intent?: any;
    shipping?: any;
    shipping_address_collection?: any;
    shipping_options: any[];
    shipping_rate?: any;
    status: string;
    submit_type?: any;
    subscription: string;
    success_url: string;
    total_details: TotalDetails;
    url?: any;
}

interface StripeSession {
    object: Object;
}