import {combineReducers} from "redux";
import {AuthState} from "../actions/auth";
import {authReducer} from "./auth";
import {PlansState} from "../actions/plans";
import {plansReducer} from "./plans";
import {CheckoutState} from "../actions/checkout";
import {checkoutReducer} from "./checkout";
import {StudioState} from "../actions/studio";
import {studioReducer} from "./studio";
import {colorsReducer} from "./colors";
import {ColorsState} from "../actions/colors";

export interface StoreState {
    auth: AuthState
    checkout: CheckoutState
    plans: PlansState
    studio: StudioState
    colors: ColorsState
}

export const rootReducer = combineReducers({
    auth: authReducer,
    checkout: checkoutReducer,
    plans: plansReducer,
    studio: studioReducer,
    colors: colorsReducer,
})