import { useEffect, useState } from 'react'



interface Constructable<T> {
    new(key: string, options?: { locale: string }): T
}

declare global {
    interface Window {
        MercadoPago: Constructable<MercadoPago>
    }
}

const useMercadopago = (publicKey: string, options?: { locale: string }) => {
    const [mercadopago, setMercadopago] = useState<MercadoPago>()

    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://sdk.mercadopago.com/js/v2'

        script.addEventListener('load', () => {
            setMercadopago(new window.MercadoPago(publicKey, options))
        })

        document.body.appendChild(script)

        return () => {
            let iframe = document.body.querySelector('iframe[src*="mercadolibre"]')

            if (iframe) {
                document.body.removeChild(iframe)
            }

            document.body.removeChild(script)
        }
    }, [])

    return mercadopago
}

export default useMercadopago

export type MercadoPago = {
    cardForm: (e: any) => any
    checkout: (e: any) => any
    constructor: (key: string, options: { locale: string }) => any
    createCardToken: (e: any, t: any) => Promise<any>
    getIdentificationTypes: () => Promise<any>
    getInstallments: (e: any) => Promise<any>
    getIssuers: (e: any) => Promise<any>
    getPaymentMethods: (e: any) => Promise<any>
    bricks: () => Promise<any>
}

