import {CheckoutAction, CheckoutState} from "../actions/checkout";
import {CheckoutActionTypes, GlobalActionTypes} from "../actions/types";

const initialState = {
    submitting: false
} as CheckoutState

export const checkoutReducer = (state: CheckoutState = initialState, action: CheckoutAction) => {
    switch (action.type) {
        case CheckoutActionTypes.SELECT_PLAN:
            return {...state, selectedPlan: action.payload}
        case CheckoutActionTypes.ADD_COUPON:
            return {...state, coupon: action.payload}
        case CheckoutActionTypes.SELECT_PAYMENT_METHOD:
            return {...state, selectedPaymentMethod: action.payload}
        case CheckoutActionTypes.CREATE_PAYMENT_INTENT:
            return {...state, submitting: true, error: undefined}
        case CheckoutActionTypes.PAYMENT_SUCCESSFUL:
            return {
                ...state,
                selectedPaymentMethod: undefined,
                success: true,
                submitting: false,
                payment_intent: action.payload,
                selectedPlan: null
            }
        case CheckoutActionTypes.PAYMENT_ERROR:
            return {...state, success: false, submitting: false, error: action.payload}
        case GlobalActionTypes.CLEAR:
            return {selectedPlan: null, submitting: false,}
        default:
            return state
    }
}