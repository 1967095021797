import React from 'react';
import { MemberPlan } from "../../../actions/plans";
import { useSelector } from "react-redux";
import { StoreState } from "../../../reducers";
import { ColorsState } from "../../../actions/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import {formatCurrency} from "../../../currency_formatters";

interface Props {
    plan: MemberPlan
    paramCurrency?: string
    checkoutMode?: boolean
}

const PlanCard = ({ plan, paramCurrency, checkoutMode = false }: Props) => {
    const colorsState = useSelector<StoreState, ColorsState>(state => state.colors)
    const studioCurrency = useSelector<StoreState, string>(state => (state.studio?.studio?.preferences?.currency ?? "mxn"))

    const getDaysDuration = () => {
        let text = `${plan.interval_count} `
        if (plan.interval === "day") {
            text += plan.interval_count === 1 ? "día" : "días"
        } else if (plan.interval === "week") {
            text += plan.interval_count === 1 ? "semana" : "semanas"
        } else if (plan.interval === "month") {
            text += plan.interval_count === 1 ? "mes" : "meses"
        } else if (plan.interval === "year") {
            text += plan.interval_count === 1 ? "año" : "años"
        }
        return <span>{text}</span>
    }

    const getIntervalTypeText = () => {
        let text = ""
        if (plan.interval === "day") {
            text += plan.interval_count === 1 ? "día" : "días"
        } else if (plan.interval === "week") {
            text += plan.interval_count === 1 ? "semana" : "semanas"
        } else if (plan.interval === "month") {
            text += plan.interval_count === 1 ? "mes" : "meses"
        } else if (plan.interval === "year") {
            text += plan.interval_count === 1 ? "año" : "años"
        }
        return text.toUpperCase()
    }

    const getPlanPrice = () => {
        const currency = paramCurrency || studioCurrency
        const planPrice = parseFloat(plan.price)
        if (plan.promotions.length > 0) {
            const promotion = plan.promotions[0]
            if (promotion.is_percent) {
                const price = planPrice - (planPrice * (promotion.discount_amount / 100))
                return formatCurrency(price, currency) //`${currency === "pen" ? "S/" : "$"}${Math.round(price)}`
            } else {
                const price = planPrice - promotion.discount_amount
                return formatCurrency(price, currency) //`${currency === "pen" ? "S/" : "$"}${Math.round(price)}`
            }
        }
        if (planPrice > 0) {
            return formatCurrency(planPrice, currency) // `${currency === "pen" ? "S/" : "$"}${Math.round(planPrice)}`
        }
        return "GRATIS"
    }
    return (
        <div
            className={`card card-custom shadow-xs min-h-200px mb-5 py-10 text-center font-size-lg ${colorsState.text === 'white' ? 'text-white' : 'text-dark'}`}
            style={{
                backgroundColor: colorsState.bg,
                // border: "1px solid rgb(95, 95, 95)",
                borderRadius: "20px"
            }}>
            <div className="px-1">
                <p className='font-size-sm'>{plan.name} {plan.is_subscription ? <FontAwesomeIcon color="MediumAquamarine" icon={faSyncAlt} /> : <React.Fragment />}</p>

                {/* <div style={{ textDecoration: "line-through", color: colorsState.fg }}
                    className="font-size-sm mb-n4 p-0 ">{plan.promotions.length === 0 ? '' : plan.price}
                </div> */}

                {/* CREDITOS */}
                <div className=" display3 font-weight-bold mt-n2">{plan.no_limit_credits ? plan.interval_count : plan.credits} </div>
                <div className=" font-size-  mt-n2 mb-4">{plan.no_limit_credits ? getIntervalTypeText() : "CLASES"}</div>


                {/* PRECIO */}
                <div className="d-flex justify-content-center align-items-center mb-3">
                    <div style={{ textDecoration: "line-through", color: colorsState.fg }}
                        className="font-size-sm p-0 mr-1">{plan.promotions.length === 0 ? '' : plan.price}
                    </div>
                    <div style={{ color: colorsState.fg}}
                        className={`${plan.promotions.length === 0 ? '' : 'text-danger'} font-weight-bold display-4 `}>{getPlanPrice()}</div>
                </div>
                {/* <div className=" pb-5 ">{plan.no_limit_credits ? plan.days_duration : plan.credits} {plan.no_limit_credits ? "Días" : currency === "pen" ? "Clases" : "Créditos"}
                </div> */}


                <div className="text-muted font-size-sm">
                    {plan.is_subscription ?  <div>Renovación cada {getDaysDuration()}</div> : <div>{getDaysDuration()}</div>}
                </div>

                <div hidden={!checkoutMode}
                    className="py-7">{plan.allow_all_classes ? "Todas las clases" : plan.allowed_classes.map(c => c.name).join(", ")}
                </div>
                <button
                    hidden={checkoutMode}
                    style={{ color: colorsState.fg, borderColor: colorsState.fg }}
                    className="btn  px-8 py-3 font-weight-bolder font-size-lg mt-5 rounded-pill btn-hover-white text-center">
                    Comprar
                </button>
            </div>
        </div>
    );
};

export default PlanCard;
