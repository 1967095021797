import {AuthAction, AuthState} from "../actions/auth";
import {AuthActionTypes} from "../actions/types";

const initialState = {
    loading: true,
    isLoggedIn: false,
    submitting: false
}

export const authReducer = (state: AuthState = initialState, action: AuthAction) => {
    switch (action.type) {
        case AuthActionTypes.LOGIN_SUBMIT:
            return {loading: false, isLoggedIn: false, submitting: true, error: ""}
        case AuthActionTypes.LOGIN_SUCCESS:
            return {loading: false, isLoggedIn: true, submitting: false}
        case AuthActionTypes.LOGIN_ERROR:
            return {loading: false, isLoggedIn: false, submitting: false, error: action.payload}
        case AuthActionTypes.LOGOUT:
        case AuthActionTypes.STORAGE_TOKENS_EXPIRED:
            return {loading: false, isLoggedIn: false, submitting: false}
        default:
            return state
    }
}