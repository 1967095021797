import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import baseApi from "../../../apis/baseApi";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {selectPaymentMethod} from "../../../actions/checkout";
import {getBrandIcon} from "../../../utils/card_utils";
import AddPaymentMethod from "./AddPaymentMethod";

interface Props {
    onGoBack: VoidFunction
}

enum DisplayOptions {selectPaymentMethod, addPaymentMethod}

const PaymentMethods = ({onGoBack}: Props) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [currentDisplay, setCurrentDisplay] = useState(DisplayOptions.selectPaymentMethod);
    const [reloadCards, setReloadCards] = useState(false);


    useEffect(() => {
        setLoading(true)
        baseApi.get("/stripe/cards/").then(resp => {
            setLoading(false)
            setPaymentMethods(resp.data.data)
        }).catch(() => {
            alert("Ocurrió un error")
        })
    }, [reloadCards]);

    if (loading) {
        return <div>Cargando...</div>
    }
    if (currentDisplay == DisplayOptions.addPaymentMethod) {
        return <AddPaymentMethod onGoBack={(newCardSuccess) => {
            if (newCardSuccess) {
                setReloadCards(!reloadCards) // Trigger reloading
            }
            setCurrentDisplay(DisplayOptions.selectPaymentMethod);
        }}/>
    }
    return (
        <div>
            <div
                className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6 bg-white my-5 shadow-lg rounded-xl mx-auto pb-10">

                <div className="row justify-content-center mt-30">
                    <div className="col-12 mt-8">


                        <div className="d-flex align-items-center">
                            <button className={"btn "} onClick={(e) => {
                                onGoBack()
                            }}><FontAwesomeIcon icon={faChevronLeft}/>
                            </button>
                            <p className={"font-size-h4 align-self-center ml-2 mt-3 font-weight-bold"}>Mis métodos de
                                pago guardados</p>
                        </div>


                        {paymentMethods.map((pm) => {
                            return <div key={pm.id} onClick={() => {
                                dispatch(selectPaymentMethod(pm))
                                onGoBack()
                            }}>
                                <div className="card p-4 my-2">
                                    <p className="m-0">
                                        <span className="text-muted mr-2">
                                            <FontAwesomeIcon style={{fontSize: "16px"}} icon={getBrandIcon(pm.card)}/>
                                        </span>
                                        &middot;&middot;&middot;&middot;{pm.card.last4}
                                    </p>
                                    <p>{pm.billing_details.name}</p>
                                    <button className="my-3 btn btn-primary">Seleccionar</button>
                                </div>
                            </div>
                        })}
                        <div className="text-center mb-5">
                            <button onClick={() => {
                                setCurrentDisplay(DisplayOptions.addPaymentMethod)
                            }} className="my-3 btn btn-success btn-lg px-10 btn-pill mb-5 font-weight-bolder">
                                + Agregar nuevo método de pago
                            </button>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default PaymentMethods;

export interface Address {
    city?: any;
    country?: any;
    line1?: any;
    line2?: any;
    postal_code?: any;
    state?: any;
}

export interface BillingDetails {
    address: Address;
    email?: any;
    name?: any;
    phone?: any;
}

export interface Checks {
    address_line1_check?: any;
    address_postal_code_check?: any;
    cvc_check?: string;
}

export interface Networks {
    available: string[];
    preferred?: any;
}

export interface ThreeDSecureUsage {
    supported: boolean;
}

export interface Card {
    brand: string;
    checks: Checks;
    country: string;
    exp_month: number;
    exp_year: number;
    fingerprint?: string;
    funding?: string;
    generated_from?: any;
    last4: string;
    networks: Networks;
    three_d_secure_usage: ThreeDSecureUsage;
    wallet?: any;
}

export interface Metadata {
}

export interface PaymentMethod {
    id: string;
    object: string;
    billing_details: BillingDetails;
    card: Card;
    created: number;
    customer: string;
    livemode: boolean;
    metadata: Metadata;
    type: string;
}
