import React, {useEffect, useState} from 'react';
import baseApi from "../../../apis/baseApi";
import LoadingRoller from "../../misc/LoadingRoller";
import {Alert, Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const WompiSaleConfirmation = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [transaction, setTransaction] = useState<WompiTransaction>();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const linkId = params.get("idEnlace")
        const tr_id = params.get("idTransaccion")
        baseApi.get<WompiTransaction>(`/wompi/confirm/?link_id=${linkId}&transaction_id=${tr_id}`).then(resp => {
            setTransaction(resp.data)
            setLoading(false)
        })
    }, []);

    if (loading) {
        return <div className="text-center">
            <h4 className="mb-5">Obteniendo datos del pago</h4>
            <LoadingRoller />
        </div>
    }
    if (transaction?.esAprobada) {
        return <div className="p-10">
            <Alert className="alert-success">Pago completado.</Alert>
            <div>
                Monto: ${transaction.monto}
            </div>
            <div>

                ID: {transaction.idTransaccion}
            </div>
            <div className="h4 pt-5">
                ¡Gracias!
            </div>
            <button onClick={() => {
                navigate("/", {replace: true})
            }} className="btn btn-primary">Regresar a Inicio
            </button>
        </div>
    }
    return (
        <div className="p-10">
            <Alert className="alert-danger">El pago no pudo ser procesado.</Alert>
        </div>
    );
};
export default WompiSaleConfirmation;
export interface WompiTransaction {
    datosBitcoin: any
    datosAdicionales: DatosAdicionales
    resultadoTransaccion: string
    fechaTransaccion: string
    montoOriginal: any
    idTransaccion: string
    esReal: boolean
    esAprobada: boolean
    codigoAutorizacion: string
    mensaje: string
    formaPago: string
    monto: number
}

interface DatosAdicionales {
    Nombre: string
    EMail: string
}


interface WompiPagoEnlace {
    idAplicativo: string
    nombreEnlace: string
    monto: number
    nombreProducto: string
    usable: boolean
    transaccionCompra?: TransaccionCompra
    cantidadIntentoPagoFallidos: number
    formaPago: FormaPago
    infoProducto: InfoProducto
    configuracion: Configuracion
    cantidadMaximaCuotas: number
    transacciones: Transacciones[]
    nombreAplicativo: string
    cantidadPagosExitosos: number
    imagenes: Imagenes[]
    vigencia: Vigencia
    limitesDeUso: LimitesDeUso
    idEnlace: number
    urlQrCodeEnlace: string
    urlEnlace: string
    estaProductivo: boolean
}

interface TransaccionCompra {
    datosAdicionales: DatosAdicionales
    resultadoTransaccion: string
    fechaTransaccion: string
    montoOriginal: number
    idTransaccion: string
    esReal: boolean
    esAprobada: boolean
    codigoAutorizacion: string
    mensaje: string
    formaPago: string
    monto: number
}

interface DatosAdicionales {
    additionalProp1: string
    additionalProp2: string
    additionalProp3: string
}

interface FormaPago {
    permitirTarjetaCreditoDebido: boolean
    permitirPagoConPuntoAgricola: boolean
    permitirPagoEnCuotasAgricola: boolean
    permitirPagoEnBitcoin: boolean
}

interface InfoProducto {
    descripcionProducto: string
    urlImagenProducto: string
}

interface Configuracion {
    urlRedirect: string
    esMontoEditable: boolean
    esCantidadEditable: boolean
    cantidadPorDefecto: number
    duracionInterfazIntentoMinutos: number
    urlRetorno: string
    emailsNotificacion: string
    urlWebhook: string
    telefonosNotificacion: string
    notificarTransaccionCliente: boolean
}

interface Transacciones {
    datosAdicionales: DatosAdicionales2
    resultadoTransaccion: string
    fechaTransaccion: string
    montoOriginal: number
    idTransaccion: string
    esReal: boolean
    esAprobada: boolean
    codigoAutorizacion: string
    mensaje: string
    formaPago: string
    monto: number
}

interface DatosAdicionales2 {
    additionalProp1: string
    additionalProp2: string
    additionalProp3: string
}

interface Imagenes {
    url: string
    esPrincipal: boolean
}

interface Vigencia {
    fechaInicio: string
    fechaFin: string
}

interface LimitesDeUso {
    cantidadMaximaPagosExitosos: number
    cantidadMaximaPagosFallidos: number
}
