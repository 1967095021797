import {format, parse} from "date-fns";
import {MinCoach, TimeSlot} from "./index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons";

interface Props {
    timeSlot: TimeSlot
    textColor: string
    cardColor: string
    mutedColor: string
}

const ScheduleCard = ({timeSlot, cardColor, textColor, mutedColor} : Props) => {
    const renderCoachImage = (coaches: MinCoach[]) => {
        const coach = coaches.find(c => c.img !== null)
        if (coaches.length === 0 || coach === undefined) {
            return <FontAwesomeIcon  icon={faUserCircle} style={{fontSize: "45px", color: mutedColor}}/>
        }
        return <img alt="coach" src={coach.img}/>
    }
    let start = timeSlot.start
    if (timeSlot.start_time !== null) {
        start =  parse(timeSlot.start_time, "HH:mm:ss", timeSlot.start)
    }
    return (
        <div>
            {/* DESKTOP*/}
            <div className="card card-custom overlay mt-2 pt-2 text-center border d-none d-md-block rounded-xl" style={{backgroundColor: cardColor}}>
                <div style={{color: mutedColor, fontSize: "10px"}} className="m-0 p-0 text-uppercase">{timeSlot.coaches.map(c => c.full_name).join(", ")}</div>
                <div style={{color: textColor, fontSize: "16px"}} className="font-weight-bolder mt-1">   {format(start, "h:mm")}</div>
                <div style={{color: textColor, fontSize: "14px"}} className="font-weight-bolder mt-n1 mb-1">   {format(start, "aa")}</div>
                <div style={{color: textColor, fontSize: "12px"}} className="pb-2">{timeSlot.studio_class.name}</div>
                <div  className="overlay-layer align-items-start justify-content-between pt-1 pr-1">
                </div>
                {/* <p style={{color: textColor}} className="font-weight-bolder">   {format(start, "h:mm aa")}</p> */}
                {/* <div className="symbol symbol-40 symbol-lg-45 symbol-circle mx-auto mt-n3 pb-1">
                    {renderCoachImage(timeSlot.coaches)}
                </div> */}
                {/* <div style={{color: mutedColor}} className="font-size-xs m-0 p-0">{timeSlot.coaches.map(c => c.full_name).join(", ")}</div> */}
            </div>
            
            {/* MOBILE */}
            <div className="card card-custom overlay mt-2 pt-2 text-center mb-4 border d-md-none" style={{backgroundColor: cardColor}}>
                <div style={{color: mutedColor, fontSize: "8px"}} className="m-0 p-0 text-uppercase">{timeSlot.coaches.map(c => c.full_name).join(", ")}</div>
                <div style={{color: textColor, fontSize: "14px"}} className="font-weight-bolder mt-1">   {format(start, "h:mm")}</div>
                <div style={{color: textColor, fontSize: "12px"}} className="font-weight-bolder mt-n1 mb-1">   {format(start, "aa")}</div>
                <div style={{color: textColor, fontSize: "9px"}} className="pb-2 px-0">{timeSlot.studio_class.name}</div>
                <div  className="overlay-layer align-items-start justify-content-between pt-1 pr-1">
                </div>
            </div>
        </div>
    );
};

export default ScheduleCard;