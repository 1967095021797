import React, {useEffect, useState} from 'react';
import baseApi from "../../../apis/baseApi";
import {useNavigate} from "react-router-dom";
import {isTokenExpired} from "../../../utils/jwt_utils";

const SuccessMercadoPago = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertType, setAlertType] = useState("info");
    const [paymentId, setPaymentId] = useState<string | null | undefined>()


    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const paymentIdParam = queryParams.get("payment_id")
        setPaymentId(paymentIdParam)
        const paymentId = paymentIdParam
        const access = localStorage.getItem("access")
        if (!access || isTokenExpired(access ?? "")) {
            const status = queryParams.get("collection_status")
            if (status === "approved" || status === "authorized") {
                setAlertType("success")
                setAlertMsg(`¡Compra exitosa!`)
            } else if (status === "pending" || status === "in_process") {
                setAlertType("warning")
                setAlertMsg("Tu pago se esta procesando. Una vez recibido se vera reflejado en tu membresía.")
            } else if (status === "cancelled") {
                setAlertType("warning")
                setAlertMsg("Pago cancelado.")
            } else {
                setAlertType("danger")
                setAlertMsg("El pago no pudo ser procesado.")
            }
            setLoading(false)
        } else {
            baseApi.post<MercadoPagoPayment>("/payments-mp/confirm/", {payment_id: paymentId}).then(resp => {
                if (resp.data.status === "approved" || resp.data.status === "authorized") {
                    setAlertType("success")
                    setAlertMsg("¡Compra exitosa!")
                } else if (resp.data.status === "pending" || resp.data.status === "in_process") {
                    setAlertType("warning")
                    setAlertMsg("Tu pago se esta procesando. Una vez recibido se vera reflejado en tu membresía.")
                } else if (resp.data.status === "cancelled") {
                    setAlertType("warning")
                    setAlertMsg("Pago cancelado.")
                } else {
                    setAlertType("danger")
                    setAlertMsg("El pago no pudo ser procesado.")
                }
                setLoading(false)
            }).catch(err => {
                setAlertType("danger")
                setAlertMsg("Ocurrió un error con la compra. Contacta a soporte con este numero de referencia: " + paymentId)
                setLoading(false)
            })
        }

    }, []);

    if (loading) {
        return <div className="text-center mt-10">
            <p className="h1">Confirmando Compra...</p>
            <div className="lds-ring">
                <div className="border-success"/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    }

    return (
        <div className="m-5">
            <div className={`alert alert-${alertType}`}>{alertMsg}</div>
            <div className="mb-5">Referencia: {paymentId}</div>
            <button onClick={() => {
                navigate("/", {replace: true})
            }} className="btn btn-primary">Regresar a Inicio
            </button>
        </div>
    );
};

export default SuccessMercadoPago;

export interface Identification {
    type: string;
    number: number;
}

export interface Payer {
    id: number;
    email: string;
    identification: Identification;
    type: string;
}

export interface Metadata {
}

export interface AdditionalInfo {
}

export interface Order {
}

export interface TransactionDetails {
    net_received_amount: number;
    total_paid_amount: number;
    overpaid_amount: number;
    installment_amount: number;
}

export interface Card {
}

export interface MercadoPagoPayment {
    id: number;
    date_created: Date;
    date_approved: Date;
    date_last_updated: Date;
    money_release_date: Date;
    payment_method_id: string;
    payment_type_id: string;
    status: string;
    status_detail: string;
    currency_id: string;
    description: string;
    collector_id: number;
    payer: Payer;
    metadata: Metadata;
    additional_info: AdditionalInfo;
    order: Order;
    transaction_amount: number;
    transaction_amount_refunded: number;
    coupon_amount: number;
    transaction_details: TransactionDetails;
    installments: number;
    card: Card;
}
