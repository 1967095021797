import {Dispatch} from "redux";
import {StudioActionTypes} from "./types";
import baseApi from "../apis/baseApi";
import {getApiErrorMessage} from "../utils/api_utils";

export interface StudioState {
    loading: boolean
    fetched: boolean
    studio: Studio
    error?: string
}

export interface StudioPreferences {
    public: string;
    hours_to_cancellation: number;
    payment_processor: string;
    shoe_size_country: string;
    paypal: boolean;
    currency: string;
    accept_tc_on_purchases: boolean;
    terms_and_conditions: string | null;
}

export interface Studio {
    id: string;
    preferences: StudioPreferences;
    image: string;
    name: string;
    instagram: string;
    facebook: string;
}

export interface StudioAction {
    type: StudioActionTypes,
    payload: any
}

export const retrieveStudio = () => {
    return async (dispatch: Dispatch) => {
        dispatch<StudioAction>({
            type: StudioActionTypes.FETCHING,
            payload: null
        })
        baseApi.get<Studio>("/studios/my/").then(resp => {
            dispatch<StudioAction>({
                type: StudioActionTypes.FETCHED,
                payload: resp.data
            })
        }).catch(err => {
            dispatch<StudioAction>({
                type: StudioActionTypes.FETCH_ERROR,
                payload: getApiErrorMessage(err)
            })
        })
    }
}

