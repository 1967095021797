import React from 'react';
import {Modal} from "react-bootstrap";
import LoadingRoller from "../../misc/LoadingRoller";

interface Props {
    show: boolean
    onHide: VoidFunction
    errorMessage?: string
}

const LoadingModal = ({show, onHide, errorMessage}: Props) => {
    return (
        <Modal show={show} onHide={onHide}>
            {/*<Modal.Header></Modal.Header>*/}
            <Modal.Body className="text-center">
                {errorMessage ? <div className="badge badge-danger text-center mt-5 font-size-h4">
                        {errorMessage}
                    </div>
                    : <div>
                        <h3 className="my-3">Cargando enlace de pago</h3>
                        <LoadingRoller/>
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
};

export default LoadingModal;
