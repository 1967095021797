import {StudioAction, StudioState} from "../actions/studio";
import {StudioActionTypes} from "../actions/types";

const initialState = {
    loading: true,
    fetched: false,
    studio: {}
} as StudioState

export const studioReducer = (state: StudioState = initialState, action: StudioAction) => {
    switch (action.type) {
        case StudioActionTypes.FETCHING:
            return {...state, loading: true, fetched: true}
        case StudioActionTypes.FETCHED:
            return {...state, loading: false, fetched: true, studio: action.payload}
        case StudioActionTypes.FETCH_ERROR:
            return {...state, loading: false, fetched: true, error: action.payload}
        default:
            return state
    }
}