import {MemberPlan} from "./plans";
import {CheckoutActionTypes, GlobalActionTypes} from "./types";
import {PaymentMethod} from "../components/pages/Checkout/PaymentMethods";
import {Dispatch} from "redux";
import baseApi from "../apis/baseApi";
import {Coupon} from "../data/Coupon";

export interface CheckoutState {
    selectedPlan?: MemberPlan
    selectedPaymentMethod?: PaymentMethod
    submitting: boolean
    error?: string
    success?: boolean
    payment_intent?: PaymentIntent
    coupon?: Coupon
}

export interface CheckoutAction {
    type: CheckoutActionTypes | GlobalActionTypes
    payload: any
}

export const selectPlan = (plan: MemberPlan) => {
    return {
        type: CheckoutActionTypes.SELECT_PLAN,
        payload: plan
    } as CheckoutAction
}

export const selectPaymentMethod = (paymentMethod: PaymentMethod) => {
    return {
        type: CheckoutActionTypes.SELECT_PAYMENT_METHOD,
        payload: paymentMethod
    } as CheckoutAction
}

export const addCoupon = (coupon: Coupon) => {
    return {
        type: CheckoutActionTypes.ADD_COUPON,
        payload: coupon
    } as CheckoutAction
}

export const createPaymentIntent = (paymentMethodId: string, planId: string, promotion?: string, couponCode?: string) => {
    return async (dispatch: Dispatch) => {
        dispatch<CheckoutAction>({
            type: CheckoutActionTypes.CREATE_PAYMENT_INTENT,
            payload: null
        })
        baseApi.post('/stripe/pay-intent/', {card: paymentMethodId, plan: planId, promotion: promotion, coupon_code: couponCode}).then(resp => {
            dispatch<CheckoutAction>({
                type: CheckoutActionTypes.PAYMENT_SUCCESSFUL,
                payload: resp.data.payment_intent
            })
        }).catch(err => {
            let errorMessage = ""
            if (err.response.data.hasOwnProperty("detail")) {
                errorMessage = err.response.data.detail
            } else if (err.response.data.hasOwnProperty("error")) {
                errorMessage = err.response.data.error.message
            } else {
                errorMessage = err.response.data.payment_intent.last_payment_error.message
            }
            dispatch<CheckoutAction>({
                type: CheckoutActionTypes.PAYMENT_ERROR,
                payload: errorMessage
            })
        })
    }
}

export interface PaymentIntent {
    id: string
    object: string
    amount: number
    amount_capturable: number
    amount_details: AmountDetails
    amount_received: number
    application: any
    application_fee_amount: any
    automatic_payment_methods: AutomaticPaymentMethods
    canceled_at: any
    cancellation_reason: any
    capture_method: string
    client_secret: string
    confirmation_method: string
    created: number
    currency: string
    customer: string
    description: string
    invoice: any
    last_payment_error: any
    latest_charge: any
    livemode: boolean
    metadata: Metadata
    next_action: any
    on_behalf_of: any
    payment_method: any
    payment_method_options: PaymentMethodOptions
    payment_method_types: string[]
    processing: any
    receipt_email: string
    review: any
    setup_future_usage: string
    shipping: any
    statement_descriptor: any
    statement_descriptor_suffix: any
    status: string
    transfer_data: any
    transfer_group: any
}

interface AmountDetails {
    tip: Tip
}

interface Tip {}

interface AutomaticPaymentMethods {
    enabled: boolean
}

interface Metadata {
    registration_fee: string
    member: string
}

interface PaymentMethodOptions {
    card: PiCard
}

interface PiCard {
    installments: any
    mandate_options: any
    network: any
    request_three_d_secure: string
}
