import {Dispatch} from "redux";
import {PlansActionTypes} from "./types";
import baseApi from "../apis/baseApi";
import {getApiErrorMessage} from "../utils/api_utils";
import noAuthApi from "../apis/noAuthApi";

export interface MemberPlan {
    id: string;
    allowed_classes: { name: string }[];
    public_visible: boolean;
    name: string;
    description: string;
    price: string;
    credits: number;
    no_limit_credits: boolean;
    sku: string;
    days_duration: number;
    interval: string;
    interval_count: number;
    does_not_expire: boolean;
    day_reservations_limit: number;
    week_reservations_limit: number;
    allow_all_branches: boolean;
    allow_all_classes: boolean;
    created_on: string;
    branches: any[];
    promotions: MemberPlanPromotion[];
    is_subscription: boolean;
}

interface MemberPlanPromotion {
    id: string;
    title: string;
    is_percent: boolean;
    discount_amount: number;
    activate_on: string;
    expire_on: string;
}


export interface PlansState {
    loading: boolean
    fetched: boolean
    plans: MemberPlan[]
    selectedPlan?: MemberPlan
    error?: string
}

export interface PlansAction {
    type: PlansActionTypes,
    payload: any
}

export const fetchPlans = (branch?: string, studio?: string) => {
    return async (dispatch: Dispatch) => {
        dispatch<PlansAction>({
            type: PlansActionTypes.FETCHING,
            payload: null
        })
        if (branch && studio) {
            noAuthApi.get<MemberPlan[]>(`/plans/public/?branch=${branch}&studio=${studio}`).then(resp => {
                dispatch<PlansAction>({
                    type: PlansActionTypes.FETCHED,
                    payload: resp.data
                })
            }).catch((err) => {
                const errorMsg = getApiErrorMessage(err)
                dispatch<PlansAction>({
                    type: PlansActionTypes.FETCH_ERROR,
                    payload: errorMsg
                })
            })
        } else {
            baseApi.get<MemberPlan[]>("/plans/").then((resp) => {
                dispatch<PlansAction>({
                    type: PlansActionTypes.FETCHED,
                    payload: resp.data
                })
            }).catch((err) => {
                const errorMsg = getApiErrorMessage(err)
                dispatch<PlansAction>({
                    type: PlansActionTypes.FETCH_ERROR,
                    payload: errorMsg
                })
            })
        }
    }
}