import {Field, Form, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import yup from "../../../utils/yup";
import "yup-phone";
import FieldError from "../../misc/FieldError";
import noAuthApi from "../../../apis/noAuthApi";
import {useNavigate, useLocation} from "react-router-dom";
import {getApiErrorMessage} from "../../../utils/api_utils";
import {useDispatch, useSelector} from "react-redux";
import {AuthState, login} from "../../../actions/auth";
import {StoreState} from "../../../reducers";

const Registration = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation() as { state: { branch: string, studio: string } }
    const authState = useSelector<StoreState, AuthState>(state => state.auth)
    const [message, setMessage] = useState("");
    const [branch, setBranch] = useState("");
    const [studio, setStudio] = useState("");

    useEffect(() => {
        if (authState.isLoggedIn) {
            navigate('/', {replace: true})
            // if (checkoutState.selectedPlan) {
            //     navigate('/checkout')
            // } else {
            // }
        }
    }, [authState]);

    useEffect(() => {
        if (location.state) {
            setBranch(location.state.branch)
            setStudio(location.state.studio)
        }
    }, [location])

    const ValidationSchema = yup.object().shape({
        full_name: yup.string().required(),
        username: yup.string().email().required(),
        phone: yup.string().min(10).max(13).required(),
        password: yup.string().min(6).required(),
    })
    return (
        <div>
            <div className="row align-items-center justify-content-center">
                <div className="col-11 col-md-6 col-lg-8 col-xl-4 col-xxl-3 text-center align-self-center mt-40">
                    <div className="text-center font-size-h1 font-weight-bolder text-dark-50">Registro</div>
                    <div className="text-danger">{message}</div>
                    <Formik
                        validationSchema={ValidationSchema}
                        initialValues={{username: "", password: "", full_name: "", phone: ""}}
                        onSubmit={(values, {setSubmitting}) => {
                            setMessage("")
                            noAuthApi.post('/members/', {
                                ...values,
                                email: values.username,
                                current_member: {
                                    studio: studio,
                                    preferred_branch: branch,
                                    ...values,
                                }
                            }).then(resp => {
                                dispatch(login(values.username, values.password))

                                setSubmitting(false)
                            }).catch(err => {
                                setMessage(getApiErrorMessage(err))
                                setSubmitting(false)
                            })
                        }}>
                        {({isSubmitting}) => (
                            <Form>
                                <div className="text-danger mt-10"><FieldError name="full_name"/></div>
                                <Field className="form-control form-control-lg " name="full_name"
                                       placeholder="Nombre"/>
                                <div className="text-danger mt-5"><FieldError name="username"/></div>
                                <Field className="form-control form-control-lg " name="username" type="email"
                                       placeholder="Correo Electrónico"/>
                                <div className="text-danger mt-5"><FieldError name="phone"/></div>
                                <Field className="form-control form-control-lg" name="phone" type="tel"
                                       placeholder="Teléfono"/>
                                <div className="text-danger mt-5"><FieldError name="password"/></div>
                                <Field className="form-control form-control-lg" name="password" type="password"
                                       placeholder="Contraseña"/>
                                <button disabled={isSubmitting}
                                        className="btn btn-pill btn-lg btn-light-primary mt-5 px-15 font-weight-bold btn-block"
                                        type="submit">
                                    Registrarme
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default Registration;
