export const getApiErrorMessage = (e: any) : string => {
    //TODO: improve cases
    if (!e.response) {
        console.log(e);
        return "Error";
    }
    const error = e.response.data
    if (typeof error === 'object' && error !== null) {
        const key = Object.keys(error)[0]
        // Value will either be the message or a list with the message
        // TODO: fix
        if (typeof error[key] === 'string') {
            return error[key]
        }
        return error[key][0]
    }
    return "ERROR " + error?.response?.status
}