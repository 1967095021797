import {PlansAction, PlansState} from "../actions/plans";
import {PlansActionTypes} from "../actions/types";

const initialState = {
    loading: true,
    fetched: false,
    plans: []
}

export const plansReducer = (state: PlansState = initialState, action: PlansAction) => {
    switch (action.type) {
        case PlansActionTypes.FETCHING:
            return {loading: true, fetched: true, plans: []}
        case PlansActionTypes.FETCHED:
            return {loading: false, fetched: true, plans: action.payload}
        case PlansActionTypes.FETCH_ERROR:
            if (state.plans.length > 0) return state
            return {loading: false, fetched: true, plans: [], error: action.payload}
        default:
            return state
    }
}