import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import {useDispatch, useSelector} from "react-redux";
import {AuthState, login} from "../../../actions/auth";
import {StoreState} from "../../../reducers";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {CheckoutState} from "../../../actions/checkout";
import {StudioState} from "../../../actions/studio";

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const authState = useSelector<StoreState, AuthState>(state => state.auth)
    const checkoutState = useSelector<StoreState, CheckoutState>(state => state.checkout)
    const [branch, setBranch] = useState<string>();
    const [studio, setStudio] = useState<string>();
    const studioState = useSelector<StoreState, StudioState>(state => state.studio)


    useEffect(() => {
        if (authState.isLoggedIn && !studioState.loading) {
            if (checkoutState.selectedPlan ) {
                const paymentProcessor = studioState.studio?.preferences?.payment_processor ?? "ST";
                if (paymentProcessor === "MP") {
                    navigate('/checkout-mp', {replace: true})
                } else {
                    navigate('/checkout', {replace: true})
                }
            } else {
                navigate('/', {replace: true})
            }
        }
    }, [studioState, authState,  checkoutState, navigate]);

    useEffect(() => {
        console.log("INIT")
        const params = new URLSearchParams(location.search)
        const branch = params.get('branch')
        const studio = params.get('studio')
        setBranch(branch ?? "")
        setStudio(studio ?? "")
    }, [location, branch, studio]);


    return (
        <div className="h-100 w-100">
            <div className="row align-items-center justify-content-center">
                <div className="col-11 col-md-6 col-lg-8 col-xl-4 col-xxl-3 text-center align-self-center mt-40">
                    {/*<div className="text-center font-size-h3 font-weight-bolder">Boost App</div>*/}
                    <div className="text-center font-size-h1 font-weight-bolder text-dark-50">Inicia sesión</div>
                    <div hidden={authState.error === undefined}
                         className="text-center text-danger">{authState.error}</div>
                    <Formik initialValues={{username: "", password: ""}} onSubmit={(values, {setSubmitting}) => {
                        dispatch(login(values.username, values.password, studio))

                    }}>
                        {({isSubmitting}) => (
                            <Form>
                                <Field className="form-control form-control-lg mt-10" name="username" type="email"
                                       placeholder="Correo Electrónico"/>
                                <Field className="form-control form-control-lg mt-5" name="password" type="password"
                                       placeholder="Contraseña"/>
                                <button className="btn btn-pill btn-lg btn-light-info mt-4 px-15 font-weight-bold btn-block"
                                        type="submit" disabled={authState.submitting}>Iniciar sesión
                                </button>
                            </Form>
                        )}
                    </Formik>
                    <hr />
                    <Link to='/registration' state={{branch: branch, studio: studio}} hidden={!branch}
                          className="btn btn-link tars-block mt-5 text-muted font-size-lg">
                        ¿Aún no tienes una cuenta? <u>Regístrate</u>
                    </Link>
                </div>
                {/* <div>Inicia Sesión ↑</div> */}
            </div>
        </div>
    );
};

export default Login;
