import {Field, Form, Formik} from 'formik';
import React, {useState} from 'react';
import yup from "../../../utils/yup";
import FieldError from "../../misc/FieldError";
import {getApiErrorMessage} from "../../../utils/api_utils";
import noAuthApi from "../../../apis/noAuthApi";


const ForgotPassword = () => {
    const [success, setSuccess] = useState<boolean>()
    const [alertMessage, setAlertMessage] = useState("Error")

    const renderMessage = () => {
        if (success === undefined) return <React.Fragment/>
        return <div className={`${success ? "alert-success" : "alert-danger"}`}>
            {alertMessage}
        </div>
    }

    const ValidationSchema = yup.object().shape({
        new_password: yup.string().min(8).required(),
        re_new_password: yup.string().oneOf([yup.ref('new_password'), null], 'Las contraseñas no coinciden.').required(),
    })

    return (
        <div className="container">

            <div className="row p-10">
                <div className="col text-center">
                    <h4>Restaurar contraseña</h4>
                    {renderMessage()}
                    <Formik initialValues={{"new_password": "", "re_new_password": ""}}
                            validationSchema={ValidationSchema}
                            onSubmit={(values, {setSubmitting}) => {
                                setSuccess(undefined)
                                const urlParams = new URLSearchParams(window.location.search)

                                noAuthApi.post('auth/users/reset_password_confirm/', {
                                    uid: urlParams.get("uid"),
                                    token: urlParams.get("token"),
                                    new_password: values.new_password
                                }).then(() => {
                                    setAlertMessage("Contraseña actualizada correctamente.")
                                    setSuccess(true)
                                }).catch(err => {
                                    setAlertMessage(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            }}>
                        {({isSubmitting}) => (
                            <Form>
                                <FieldError name="new_password"/>
                                <div className="d-flex justify-content-center mt-3">
                                    <Field type="password" name="new_password" className="form-control w-50"
                                           placeholder="Nueva contraseña"/>
                                </div>
                                <FieldError name="re_new_password"/>
                                <div className="d-flex justify-content-center my-2">
                                    <Field type="password" name="re_new_password" className="form-control w-50"
                                           placeholder="Confirmar nueva contraseña"/>

                                </div>
                                <div>
                                    <button disabled={isSubmitting} className="btn btn-primary">
                                        Confirmar
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
