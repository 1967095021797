import {Dispatch} from "redux";
import jwt_decode from "jwt-decode";

import {AuthActionTypes, GlobalActionTypes} from "./types";
import {fromUnixTime, isBefore, sub} from "date-fns";
import noAuthApi from "../apis/noAuthApi";
import {getApiErrorMessage} from "../utils/api_utils";
import {retrieveStudio} from "./studio";

export interface AuthState {
    loading: boolean
    submitting: boolean
    isLoggedIn: boolean
    error?: string
}

export interface AuthAction {
    type: AuthActionTypes | GlobalActionTypes,
    payload: any
}

export interface Tokens {
    refresh: string;
    access: string;
}

export interface User {
    id: string;
    email: string;
    birthday?: any;
    gender: string;
}

export interface PreferredBranch {
    id: string;
    name: string;
}

export interface CurrentMembership {
    id: string;
    name: string;
    expire_on: Date;
}

export interface CurrentMember {
    id: string;
    emergency_contact_phone: string;
    birthday?: any;
    external_id: string;
    full_name: string;
    phone: string;
    user: User;
    shoe_size?: any;
    preferred_branch: PreferredBranch;
    current_credits: number;
    current_membership: CurrentMembership;
    credits_expiration: Date;
    img?: any;
    created_on: Date;
}

export interface UserSession {
    id: string;
    email: string;
    full_name: string;
    tokens: Tokens;
    current_member: CurrentMember;
}

export const checkLocalStorageTokens = (accessParam?: string) => {
    return (dispatch: Dispatch) => {
        let access = accessParam || null
        if (!access) {
            access = localStorage.getItem("access")
        }
        if (access) {
            let decoded = jwt_decode(access) as { exp: number, user_id: string }
            console.log("DECODE")
            console.log(decoded)
            let expiration = fromUnixTime(decoded.exp)
            let tokenValid = isBefore(sub(expiration, {hours: 1}), new Date())
            localStorage.setItem("access", access)
            if (tokenValid) {
                dispatch<AuthAction>({
                    type: AuthActionTypes.LOGIN_SUCCESS,
                    payload: null
                })
                // @ts-ignore
                dispatch(retrieveStudio())
                return
            }
        }
        dispatch<AuthAction>({
            type: AuthActionTypes.STORAGE_TOKENS_EXPIRED,
            payload: null
        })
    }
}

export const login = (username: string, password: string, studio?: string) => {
    return async (dispatch: Dispatch) => {
        dispatch<AuthAction>({
            type: AuthActionTypes.LOGIN_SUBMIT,
            payload: null
        })
        const studioParam = studio ? `?studio=${studio}` : ''
        noAuthApi.post<UserSession>(`/members/login/${studioParam}`, {username, password}).then((resp) => {
            localStorage.setItem("access", resp.data.tokens.access)
            localStorage.setItem("refresh", resp.data.tokens.refresh)
            // @ts-ignore
            dispatch(retrieveStudio())
            dispatch<AuthAction>({
                type: AuthActionTypes.LOGIN_SUCCESS,
                payload: null
            })
        }).catch((err) => {
            let errorMsg = getApiErrorMessage(err)
            dispatch<AuthAction>({
                type: AuthActionTypes.LOGIN_ERROR,
                payload: errorMsg
            })
        })
    }
}

export const logout = () => {
    return (dispatch: Dispatch) => {
        localStorage.clear()
        dispatch<AuthAction>(
            {type: AuthActionTypes.LOGOUT, payload: null}
        )
        dispatch<AuthAction>({type: GlobalActionTypes.CLEAR, payload: null})
    }
}