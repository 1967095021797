import React from "react"
import ReactDOM from "react-dom";

import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";

import {rootReducer} from "./reducers";
import App from "./components/App";
import {Provider} from "react-redux";

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>, document.querySelector("#root"))