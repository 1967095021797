import React, {useEffect, useState} from 'react';
import useMercadopago from "../../../hooks/mercadoPago";
import baseApi from "../../../apis/baseApi";
import PlanCard from "../Plans/PlanCard";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {StudioState} from "../../../actions/studio";
import {CheckoutState} from "../../../actions/checkout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {getApiErrorMessage} from "../../../utils/api_utils";
import {Button, Modal, Form as BSForm} from "react-bootstrap";
import {Coupon} from "../../../data/Coupon";
import {Field, Form, Formik} from "formik";
import FieldError from "../../misc/FieldError";
import {MercadoPagoPreference} from "../CheckoutBricksMercadoPago/interfaces/MercadoPagoPreference";

const CheckoutMercadoPago = () => {
    const checkoutState = useSelector<StoreState, CheckoutState>(state => state.checkout)
    const studioState = useSelector<StoreState, StudioState>(state => state.studio)
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [showModal, setShowModal] = useState(true);
    const [coupon, setCoupon] = useState<Coupon>();
    const mp = useMercadopago(studioState.studio.preferences.public)
    const [acceptedTC, setAcceptedTC] = useState(true)

    const navigate = useNavigate()

    useEffect(() => {
        if (checkoutState.selectedPlan && parseFloat(checkoutState.selectedPlan.price) > 0) {
            let url = `/payments-mp/?plan=${checkoutState.selectedPlan!.id}`
            const promotionId = checkoutState.selectedPlan!.promotions.length === 0 ? undefined : checkoutState.selectedPlan?.promotions[0].id
            if (promotionId) {
                url += `&promotion=${promotionId}`
            }
            baseApi.get<MercadoPagoPreference>(url).then(resp => {
                mp?.checkout({
                    preference: {id: resp.data.id},
                    render: {
                        container: ".mp-container"
                    }
                })
                setLoading(false)
            }).catch(e => {
                setErrorMsg(getApiErrorMessage(e))
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [mp]);

    useEffect(() => {
        if (checkoutState.selectedPlan && parseFloat(checkoutState.selectedPlan.price) > 0) {
            setLoading(true)
            let url = `/payments-mp/?plan=${checkoutState.selectedPlan!.id}`
            const promotionId = checkoutState.selectedPlan!.promotions.length === 0 ? undefined : checkoutState.selectedPlan?.promotions[0].id
            if (promotionId) {
                url += `&promotion=${promotionId}`
            }
            if (coupon) {
                url += `&coupon_code=${coupon.redeem_code}`
            }
            baseApi.get<MercadoPagoPreference>(url).then(resp => {
                mp?.checkout({
                    preference: {id: resp.data.id},
                    render: {
                        container: ".mp-container-discount"
                    }
                })
                setLoading(false)
            }).catch(e => {
                setErrorMsg(getApiErrorMessage(e))
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [coupon]);

    useEffect(() => {
        if (studioState.studio.preferences.accept_tc_on_purchases) {
            setAcceptedTC(false)
        }
    }, [studioState]);



    const renderModal = () => {
        if (studioState) {
            if (showModal) {
                if (studioState.studio.id === "e14ea4e8-1a2d-4a04-8f04-eb8fa4ffc8f9") {
                    return <Modal centered show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header className="font-weight-bolder font-size-h4">¡Ayúdanos a cuidarte!</Modal.Header>
                        <Modal.Body>
                            <div>
                                Recuerda que para poder ingresar a Vértika Studio, es necesario contar con cartilla
                                de
                                vacunación COVID-19. ¡Tu salud y seguridad siempre serán nuestra prioridad!
                            </div>
                            <div>
                                Al comprar un paquete o membresía, confirmas que estás de acuerdo con este
                                requerimiento.
                            </div>
                            <div className="font-weight-bolder mt-3">
                                Los créditos son personales y no son transferibles entre usuarios.
                            </div>
                            <div className="text-center mt-4">
                                <Button onClick={() => setShowModal(false)}
                                        className="px-20 bg-dark border-0">Entendido</Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                }
            }
        }
        return <React.Fragment/>
    }
    const renderFreePlanButton = () => {
        if (parseFloat(checkoutState.selectedPlan!.price) > 0) return <React.Fragment/>
        return <div className="text-center">
            <button
                className="btn btn-primary btn-lg  font-weight-bolder mt-5 px-20"
                onClick={() => {
                    setErrorMsg(undefined)
                    baseApi.post("/sales/freebuy/", {
                        plan: checkoutState.selectedPlan!.id
                    }).then(resp => {
                        console.log(resp.data)
                        navigate("/purchase-details")
                    }).catch(err => {
                        setErrorMsg(getApiErrorMessage(err))
                    })
                }}
            >
                Comprar
            </button>
        </div>
    }

    return (
        <div>
            <div className='col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6 bg-white my-5 shadow-lg rounded-xl mx-auto'>
                {renderModal()}

                {/* TITULO Y BACK BUTTON */}
                <div className="d-flex text-center align-items-center mb-2 pt-3">
                    <button className="btn mr-auto" onClick={() => {
                        navigate(-1)
                    }}><FontAwesomeIcon icon={faChevronLeft}/>
                    </button>
                    <div className="text-center font-size-h3 ml-n10  mr-auto">Confirma tu compra</div>
                </div>

                {/* ERROR */}
                <div hidden={checkoutState.error === undefined}
                     className="alert alert-danger">Error: {checkoutState.error}
                </div>

                {/* PLAN CARD */}
                <div className="row justify-content-center">
                    <div className="col-10 col-xl-10 col-xxl-8 ">
                        <PlanCard plan={checkoutState.selectedPlan!} checkoutMode={true}/>
                    </div>
                </div>


                {/* CUPON */}
                <div className="col-12 col-xl-10 col-xxl-8  mx-auto ">
                    <Formik initialValues={{coupon_code: ""}} onSubmit={(values, {setSubmitting, setFieldError}) => {
                        baseApi.post<Coupon>("/coupons/validate/", {
                            ...values,
                            plan: checkoutState.selectedPlan!.id,
                        }).then(resp => {
                            setCoupon(resp.data)
                        }).catch(err => {
                            setFieldError("coupon_code", getApiErrorMessage(err))
                            setSubmitting(false)
                        })
                    }}>
                        {({values, isSubmitting}) => (
                            <Form>
                                <div className="d-flex px-6">
                                    <div className="w-75">
                                        <Field name="coupon_code"
                                               className="form-control form-control-"
                                               placeholder="Código de descuento"/>
                                        <FieldError name="coupon_code"/>
                                    </div>
                                    <div hidden={coupon !== undefined} className="">
                                        <Button className='font-size-sm text-success'
                                                type="submit"
                                                disabled={isSubmitting} variant="outline-secondary"
                                        >
                                            {/* <FontAwesomeIcon icon={faCheck}/> */}
                                            Aplicar
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <div hidden={!coupon} className="text-success text-center">{coupon?.title} (-{coupon?.discount_amount}{coupon?.is_percent ? '%' : ''})</div>
                </div>
                <div hidden={!studioState.studio.preferences.accept_tc_on_purchases} className="text-center">
                    <div className="d-inline-flex align-items-center" >
                        <BSForm.Check id="accept-tc-check" className="font-size-lg"  type="checkbox" label=" Acepto los términos y condiciones" onChange={(ev) => {
                            setAcceptedTC(ev.target.checked)
                        }} />
                        <Button onClick={() => window.open(studioState.studio.preferences.terms_and_conditions ?? "", "_blank")} variant="link" size="sm"><FontAwesomeIcon icon={faExternalLinkAlt} /></Button>
                    </div>
                </div>

                <div hidden={loading || coupon !== undefined || !acceptedTC} className="mp-container p-5 text-center"/>
                <div hidden={loading || !acceptedTC } className="mp-container-discount p-5 text-center"/>
                <div className="text-center text-danger">
                    {errorMsg}
                </div>

                {/* BOTON PAGAR */}
                {renderFreePlanButton()}
                <div className="text-center">
                    <div hidden={!loading} className="lds-ring">
                        <div className="border-primary"/>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
                <p className="text-muted text-center font-size-sm pt-2 pb-10">Al pagar no abandones la página hasta recibir la
                    pantalla de confirmación y ser redirigido a esta página</p>
            </div>
        </div>
    );
};

export default CheckoutMercadoPago;
