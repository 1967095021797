import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import {endOfWeek, format, formatISO, parseISO, startOfWeek} from "date-fns";
import {addDays, addWeeks, isSameDay, subWeeks} from "date-fns/fp";
import TimeSlotCard from "./TimeSlotCard";
import {es} from "date-fns/locale";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {ColorsState, updateColors} from "../../../actions/colors";
import noAuthApi from "../../../apis/noAuthApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

const Schedules = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
    const colorsState = useSelector<StoreState, ColorsState>(state => state.colors)
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        if (!colorsState.updated) {
            const fg = params.get('fg') || '#2295F3'
            const bg = params.get('bg') || 'white'
            const textColor = params.get('textColor') || 'black'
            const cardColor = params.get('cardColor') || 'white'
            const mutedColor = params.get('mutedColor') || '#B5B5C3'
            dispatch(updateColors({fg, bg, textColor, cardColor, mutedColor, updated: true}))
        }
    }, [location]);

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const branch = params.get('branch') || undefined
        const studio = params.get('studio') || undefined
        if (branch && studio) {
            const start = formatISO(startOfWeek(currentDate, {weekStartsOn: 1}))
            const end = formatISO(endOfWeek(currentDate, {weekStartsOn: 1}))
            noAuthApi.get(`/schedules/slots/public/?branch=${branch}&start=${start}&end=${end}`).then(resp => {
                const data: TimeSlot[] = resp.data.map((t: any) => ({
                    ...t,
                    start: parseISO(t.start),
                } as TimeSlot))
                const sortedData = data.sort((a, b) => (a.start.getTime() - b.start.getTime()))
                setTimeSlots(sortedData)
                setLoading(false)
            })
        } else {
            navigate('/login', {replace: true})
        }
    }, [currentDate, location]);


    const renderDayTimeSlots = (day: Date) => {
        let filteredTimeSlots = timeSlots.filter(ts => isSameDay(ts.start, day))
        return filteredTimeSlots.map(ts => <TimeSlotCard
            key={ts.id} timeSlot={ts} cardColor={colorsState.cardColor} mutedColor={colorsState.mutedColor}
            textColor={colorsState.textColor}
        />)
    }

    function onArrowForward() {
        setCurrentDate(addWeeks(1, currentDate))
    }

    function onArrowBack() {
        const day = subWeeks(1, currentDate)
        if (endOfWeek(day) < new Date()) {
            return
        }
        setCurrentDate(day)
    }

    const renderHeader = () => {
        const weekStart = startOfWeek(currentDate, {weekStartsOn: 1})
        let days: Date[] = []
        for (let i = 0; i < 7; i++) {
            const day = addDays(i, weekStart)
            days.push(day)
        }

        return <div className="d-flex flex-row text-center justify-content-between" style={{width: '100%'}}>
            {days.map((d) =>
                (
                    <div key={d.toString()} className="px-0" style={{width: '14.16%'}}>
                        <div className=''>
                            <div
                                style={{backgroundColor: colorsState.cardColor}}
                                className="card card-custom rounded-circle mx-auto shadow-sm h-35px h-sm-50px w-35px w-sm-50px">
                                <h6 style={{color: colorsState.textColor}}
                                    className="font-weight-boldest text-center my-auto">
                                    {format(d, " d", {locale: es})}
                                </h6>
                            </div>
                            <p style={{color: colorsState.mutedColor}}
                               className="text-uppercase font-weight-bolder text-center pt-2">{format(d, "E", {locale: es})}</p>
                        </div>
                        {renderDayTimeSlots(d)}
                    </div>
                )
            )}
        </div>
    }

    if (loading) {
        return <div className="p-5">Cargando Horarios...</div>
    }
    return (
        <div className="pt-5">
            <div className="mb-10">
                <div className="d-flex align-items-center flex-wrap justify-content-between mt-10 mb-n5">
                    <button onClick={onArrowBack}
                            className="btn btn-sm btn-light btn-icon btn-circle bg-white text-dark shadow-sm mr-2"
                            data-toggle="tooltip" data-original-title="Semana anterior">
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </button>
                    <h6 className="text-uppercase text-dark font-weight-bold mt-2 mr-5 align-middle ml-5 text-center">{format(currentDate, "MMMM yyyy", {locale: es})}</h6>

                    <button onClick={onArrowForward}
                            className="btn btn-sm btn-light btn-icon btn-circle bg-white text-dark shadow-sm"
                            data-toggle="tooltip" data-original-title="Siguiente semana">
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </button>
                </div>
            </div>
            {renderHeader()}
        </div>
    );
};

export default Schedules;

export interface TimeSlot {
    id: string,
    start: Date,
    start_time: string,
    schedule: string,
    available_spaces: number
    studio_class: StudioClass
    minutes_duration: number
    people_limit: number
    coaches: MinCoach[],
    room: Room | null
    occupied_seats: string[],
    activate_days_before: number | null
    publish_date?: string
}

export interface MinCoach {
    id: string
    full_name: string
    img: string
}

export interface Seat {
    id: string;
    label: string;
    row: number;
    col: number;
    is_coach: boolean;
}

export interface Room {
    id: string;
    seats: Seat[];
    archived: boolean;
    name: string;
    people_limit: number;
    rows: number;
    cols: number;
    branch: string;
    uses_map: boolean;
}

export interface StudioClass {
    id: string,
    name: string,
    color: string,
    allow_manual_credits: boolean
}