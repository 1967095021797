import {ColorsActionTypes} from "./types";

export interface ColorsState {
    cardColor: string
    textColor: string
    mutedColor: string
    bg: string
    fg: string
    text: string
    updated: boolean
}

export interface ColorAction {
    type: ColorsActionTypes;
    payload: ColorsState;
}

export const updateColors = (data: any) => {
    return {
        type: ColorsActionTypes.UPDATE,
        payload: data
    }
}