import React from 'react';
import {createBrowserRouter, Route, RouterProvider, Routes} from "react-router-dom";

import "../assets/styles/loader.css"
import "../assets/styles/brand_light.css"
import "../assets/styles/header_light.css"
import "../assets/styles/header_menu_light.css"
import "../assets/styles/primjsbundle.css"
import "../assets/styles/stylebundle.css"
import "../assets/styles/aside_light.css"
import "../assets/styles/style.css"
import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import Registration from "./pages/auth/Registration";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Checkout from "./pages/Checkout";
import AddPaymentMethod from "./pages/Checkout/AddPaymentMethod";
import PurchaseSuccess from "./pages/Checkout/PurchaseSuccess";
import PaymentMethods from "./pages/Checkout/PaymentMethods";
import SubscriptionSuccess from "./pages/Checkout/SubscriptionSuccess";
import CheckoutBricksStudioLoadScreen from "./pages/CheckoutBricksMercadoPago/CheckoutBricksStudioLoadScreen";
import CheckoutMercadoPago from "./pages/CheckoutMercadoPago";
import SuccessMercadoPago from "./pages/CheckoutMercadoPago/Success";
import WompiSaleConfirmation from "./pages/CheckoutWompi/WompiSaleConfirmation";
import Schedules from "./pages/Schedules";
import RegistrationFees from "./pages/Plans/RegistrationFees";

const Root = () => {
    return (
        <div>
            <div className="container">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/registration" element={<Registration/>}/>
                    <Route path="/forgot-password" element={<ForgotPassword/>}/>
                    {/* STRIPE */}
                    <Route path="/checkout" element={<Checkout/>}/>
                    <Route path="/purchase-details" element={<PurchaseSuccess/>}/>
                    <Route path="/subscription-success" element={<SubscriptionSuccess/>}/>
                    {/* MERCADOPAGO */}
                    <Route path="/checkout-bricks-mp" element={<CheckoutBricksStudioLoadScreen/>}/>
                    <Route path="/checkout-mp" element={<CheckoutMercadoPago/>}/>
                    <Route path="/success-mp" element={<SuccessMercadoPago/>}/>
                    {/* WOMPI */}
                    <Route path="/wompi-confirmation" element={<WompiSaleConfirmation/>}/>
                    {/*  SCHEDULES  */}
                    <Route path={"/schedules"} element={<Schedules/>}/>
                    {/* REGISTRATION FEES */}
                    <Route path={"/registration-fees"} element={<RegistrationFees/>}/>
                </Routes>
            </div>
        </div>
    );
};

const router = createBrowserRouter([
    {path: "*", Component: Root},
])
const App = () => {
    return <RouterProvider router={router}/>
}

export default App;
