import React from 'react';
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {PaymentIntent} from "../../../actions/checkout";
import {useNavigate} from "react-router-dom";

const PurchaseSuccess = () => {
    const navigate = useNavigate()
    const paymentIntent = useSelector<StoreState, PaymentIntent | undefined>(state => state.checkout.payment_intent)

    if (!paymentIntent) {
        return (
            <div>
                <div className="alert alert-success mt-2">¡Compra exitosa!</div>
                <button onClick={() => {
                    navigate("/", {replace: true})
                }} className="btn btn-primary">Regresar a Inicio</button>
            </div>
        );
    }
    return (
        <div className="mt-2">
            <div className="alert alert-success">¡Compra exitosa!</div>
            <div className="card my-5 p-4">
                <div className="text-uppercase mb-3">{paymentIntent.description}</div>
                <div className="text-muted text-uppercase mb-3">{paymentIntent.id}</div>
                <div className="font-size-h4">$ {paymentIntent.amount / 100} {paymentIntent.currency}</div>
            </div>
            <button onClick={() => {
                navigate("/", {replace: true})
            }} className="btn btn-primary">Regresar a Inicio</button>
        </div>
    );
};

export default PurchaseSuccess;
