import {ColorAction, ColorsState} from "../actions/colors";
import {ColorsActionTypes} from "../actions/types";

const initialState = {
    bg: 'rgb(58, 58, 58)',
    fg: "#fff",
    text: "black",
    updated: false,
    cardColor: "white",
    textColor: "black",
    mutedColor: "#B5B5C3"
}as ColorsState

export const colorsReducer = (state: ColorsState = initialState, action: ColorAction) => {
    switch (action.type) {
        case ColorsActionTypes.UPDATE:
            return {...state, ...action.payload};
        default:
            return state
    }
}