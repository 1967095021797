export enum GlobalActionTypes {
    CLEAR = "CLEAR"
}

export enum AuthActionTypes {
    LOGIN_SUBMIT = "LOGIN_SUBMIT",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_ERROR = "LOGIN_ERROR",
    STORAGE_TOKENS_EXPIRED = "STORAGE_TOKENS_EXPIRED",
    LOGOUT = "LOGOUT"
}

export enum PlansActionTypes {
    FETCHING = "FETCHING_PLANS",
    FETCHED = "FETCHED_PLANS",
    FETCH_ERROR = "FETCH_PLAN_ERROR"
}

export enum CheckoutActionTypes {
    SELECT_PLAN = "SELECT_PLAN",
    ADD_COUPON = "ADD_COUPON",
    SELECT_PAYMENT_METHOD = "SELECT_PAYMENT_METHOD",
    CREATE_PAYMENT_INTENT = "CREATE_PAYMENT_INTENT",
    PAYMENT_SUCCESSFUL = "PAYMENT_SUCCESSFUL",
    PAYMENT_ERROR = "PAYMENT_ERROR",
}

export enum StudioActionTypes {
    FETCHING = "FETCHING_STUDIO",
    FETCHED = "FETCHED_STUDIO",
    FETCH_ERROR = "FETCH_STUDIO_ERROR"
}

export enum ColorsActionTypes {
    UPDATE = "UPDATE_COLORS"
}