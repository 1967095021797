import React, {useEffect, useRef} from 'react';
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import yup from '../../../utils/yup';
import {Field, Form, Formik} from 'formik';
import FieldError from '../../misc/FieldError';
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Studio} from "../../../actions/studio";
import {loadStripe} from "@stripe/stripe-js/pure";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import baseApi from "../../../apis/baseApi";


const BillingSchema = yup.object().shape({
    billing_details: yup.object().shape({
        name: yup.string().required(),
        address: yup.object().shape({
            line1: yup.string().required(),
            city: yup.string().required(),
            state: yup.string().required(),
        })
    })
})

interface Props {
    onGoBack: (newCardSuccess: boolean) => void
}

const AddPaymentMethod = ({onGoBack} : Props) => {
    const stripe = useStripe();
    const elements = useElements();

    const clientSecret = useRef("");

    useEffect(() => {
        baseApi.post("/stripe/setup-intent/").then(res => {
            clientSecret.current = res.data.client_secret
        }).catch(err => {
        })
    }, []);


    return (
        <div className="mt-12 col-12 col-md-5 col-lg-4 mx-auto bg-white shadow-lg pt-5 rounded-xl">
            <div className="d-flex align-items-center mb-5">
                <button className={"btn"} onClick={() => {
                    onGoBack(false)
                }}><FontAwesomeIcon icon={faChevronLeft}/>
                </button>
                <p className={"font-size-h4 align-self-center ml-2 mt-3 font-weight-bold"}>Agregar nuevo método de pago</p>
            </div>
            <Formik validationSchema={BillingSchema} initialValues={{
                billing_details: {
                    name: "",
                    address: {
                        line1: "",
                        line2: "",
                        city: "",
                        state: "",
                        country: "MX",
                        postal_code: ""
                    }
                },
            }} onSubmit={async (values, {setSubmitting}) => {
                if (!stripe || !elements || clientSecret.current === "") {
                    alert("La pagina no esta lista")
                    return
                }
                const result = await stripe.confirmCardSetup(clientSecret.current, {
                    payment_method: {
                        // @ts-ignore
                        card: elements.getElement(CardElement),
                        billing_details: values.billing_details
                    }
                })

                if (result.error) {
                    // display error
                    alert(result.error.message)
                    setSubmitting(false)
                } else {
                    // SUCCESS
                    onGoBack(true)
                }
            }}>
                {({isSubmitting}) => (
                    <Form>

                        <Field className="form-control mt-2 py-8" name="billing_details.name"
                               placeholder="Nombre en tarjeta"/>
                        <FieldError name="billing_details.name"/>
                        <Field className="form-control  mt-2 py-8" name="billing_details.address.line1"
                               placeholder="Dirección"/>
                        <FieldError name="billing_details.address.line1"/>
                        <div className="row">
                            <div className="col">
                                <Field className="form-control mt-2 py-8" name="billing_details.address.city"
                                       placeholder="Ciudad"/>
                                <FieldError name="billing_details.address.city"/>
                            </div>
                            <div className="col">
                                <Field className="form-control mt-2 py-8" name="billing_details.address.state"
                                       placeholder="Estado"/>
                                <FieldError name="billing_details.address.state"/>
                            </div>
                        </div>
                        <div>
                            <Field className="form-control mt-2 py-8" name="billing_details.address.postal_code"
                                   placeholder="Código Postal"/>
                            <FieldError name="billing_details.address.postal_code"/>
                        </div>
                        <div className="p-4 py-6 my-2 rounded border bg-white">
                            <CardElement/>
                        </div>
                        <div className="text-center mb-5">
                            <button type="submit" disabled={isSubmitting}
                                    className="my-3 btn btn-success btn-lg px-10 btn-pill mb-5 font-weight-bolder">
                                Continuar
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
        ;
};

const Wrapper = ({onGoBack}: Props) => {
    const studio = useSelector<StoreState, Studio>(state => state.studio.studio)

    const stripePromise = loadStripe(studio.preferences.public, {locale: "es"})
    return <Elements stripe={stripePromise}>
        <AddPaymentMethod onGoBack={onGoBack}/>
    </Elements>
}

export default Wrapper;
